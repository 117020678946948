<template>
  <div class="User">
    <TopTabbar title="个人中心" text="" :arrow="false"/>
    <div class="center">
      <div class="userInfo">
        <img :src="User.avatar" class="active">
        <div class="Info">
          <h1>{{ User.username }}</h1>
          <p>手机号:{{ User.mobile }}</p>
        </div>
      </div>
      <div class="balance">
        洗车卡(次): {{ User.count }}
      </div>
      <div class="util">
        <div class="item" @click="setting">
          <span>
            <img src="./images/user.png">
            个人设置
          </span>
          <span></span>
        </div>
        <div class="item" @click="myAddress">
          <span>
            <img src="./images/address.png">
            我的地址
          </span>
          <span></span>
        </div>
        <div class="item" @click="phone">
          <span>
            <img src="./images/phone.png">
            联系客服
          </span>
          <span></span>
        </div>
      </div>
    </div>
    <Tabbar class="Tabbar" :value="1"/>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router'
  import { reqUser } from '../../API/user'
  import { onMounted, reactive } from 'vue'
  import { showDialog } from 'vant'

  const $router =  useRouter()
  const User = reactive({})

  onMounted(async () => {
    const result = await reqUser()
    if(result.code == 1) Object.assign(User,result.data)
  })

  //个人设置
  const setting = () => {
    $router.push('/User/settings')
  }

  //我的地址
  const myAddress = () => {
    $router.push('/User/address')
  }

  //客服电话
  const phone = () => {
    showDialog({
      title: '400-808-0864',
      theme: 'round-button',
    }).then(() => {
      // on close
    });
  }
</script>

<style lang="scss" scoped>
  .User{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #f6f6f6;
    .center{
      position: relative;
      width: 100%;
      height: calc(100% - 50px - 46px);
      overflow: auto;
      .userInfo{
        display: flex;
        align-items: center;
        width: 100%;
        height: 166px;
        background: url('./images/cmjss.png') no-repeat;
        background-size: cover;
        .active{
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background-color: #e5a0c4;
          margin: 0 20px;
        } 
        .Info{
          color: white;
          h1{
            font-size: 24px;
            margin-bottom: 10px;
          }
        }
      }
      .balance{
        width: 100%;
        border: 2px solid #5f523b;
        background-color: #2d2b2c;
        color: #a98c51;
        font-weight: bold;
        padding: 20px 0;
        text-align: center;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
      }
      .util{
        width: 96%;
        margin: 20px auto 0 auto;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        .item{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 15px;
          span{
            display: flex;
            align-items: center;
            img{
              width: 30px;
              height: 30px;
              margin-right: 10px;
              margin-top: -3px;
            }
            &:nth-of-type(2){
              width: 10px;
              height: 10px;
              border-top: 1px solid #b9b8b8;
              border-right: 1px solid #b9b8b8;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    .Tabbar{
      position: sticky;
    }
  }
</style>